import React from "react"
import Layout from "@components/layout";
import { StaticImage } from "gatsby-plugin-image";

// Import SVG
import listSVG1 from '@images/icon_list1.svg';
import imgMt4 from '@images/mt4.png';

// import Animation modules
import { motion } from "framer-motion"

// 言語用Jsonファイル
import { LangSetting } from "@components/language-setting.js";
import langEn from "@content/locales/en/mt4-desktop-win.json";
import langJa from "@content/locales/ja/mt4-desktop-win.json";

// 共通Link用ファイル
import { LinkSetting } from "@components/link-setting.js";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const Mt4DesktopWin = ( props ) => {
  
  // 言語設定
  const setLang = LangSetting( props, langEn, langJa ).SetLang;
  const postLang = LangSetting( props, langEn, langJa ).PostLang;
  
  // Seo情報設定
  const pathName = 'mt4-desktop-win'; // ファイル名
  const title = postLang["page-title"];
  const description = postLang["page-description"];
  const keywords = postLang["page-keywords"];
  const seoTag = SeoContents( pathName, setLang, title, description, keywords, "", "" );

  // リンク設定
  const signPath1 = LinkSetting( setLang ).Register;
  const platDl1 = LinkSetting( setLang ).PlatDl1;

  // staticimage(※相対パスで記載)
  const image_01 = "../images/pf_wd.webp";
  const image_02 = "../images/pf_wd.webp";

  return (
  <>
    {seoTag}
    <Layout>
      <div className="FV pt-5 pb-8 bg-fixed" langType={setLang}>
        <div className="max-w-xl mx-auto px-6 md:max-w-5xl md:pt-5 pt-2 ">
          <div className=" text-left h1_filed">
            <h1 className="md:text-[60px] text-[35px] font-semibold">{postLang["plt1-h1"]}</h1>
            <div className="text-base max-w-[680px]">
              <p>{postLang["plt1-h1-txt"]}{postLang["plt1-h1-txt_2"]}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto">
        <div className="relative overflow-hidden">
          <div className="pt-4 md:pb-24 md:h-[450px] overflow-hidden relative">
            <div className="max-w-xl mx-auto md:max-w-5xl md:w-[100%]">
              <div className="max-w-xl  mx-auto md:max-w-5xl px-6 ">
                <div className="text-left md:mt-7 md:mb-5 mb-0   mt-5 ">
                  <h2 className="font-semibold tracking-wide text-[26px] md:text-[40px]">{postLang["plt1-h2"]}</h2>
                </div>
              </div>
              <div className="flex justify-between relative">
                <div className="xl:w-[100%] md:w-[50%]">
                  <p className="p-6 md:pl-6  md:max-w-xl"> {postLang["plt1-txt_1"]}<br></br>{postLang["plt1-txt_2"]}<br></br>{postLang["plt1-txt_3"]}<br></br>{postLang["plt1-txt_4"]}<br></br>{postLang["plt1-txt_5"]}</p>
                </div>
              </div>
            </div>
          </div>
          <motion.div className="hidden text-left md:w-[100%] md:block overflow-hidden absolute top-[26%] left-[55%]" initial={{ opacity:0 ,x:200 }} whileInView={{ opacity:1 ,x:0 }} transition={{ duration: 2 }} viewport={{ once: true }}>
            <StaticImage className="md:w-[450px] md:h-[380px] overflow-hidden" alt="" src={image_01} /> </motion.div>
          <div className="platform pb-8 bg-fixed">
            <div className="md:relative md:max-w-5xl md:flex md:mx-auto">
              <motion.div className="text-center pt-5 mb-9 -mx-12 md:hidden" initial={{ opacity:0 ,x:200 }} whileInView={{ opacity:1 ,x:0 }} transition={{ duration: 2 }} viewport={{ once: true }}>
                <StaticImage className="max-w-[400px] w-full ml-[150px] " alt="" src={image_02} /> </motion.div>
              <div className="px-6 md:order1 md:w-[52%] md:ml-18 md:mt-0px] lg:mt-[-15px] md:pb-10"> <a href={platDl1} className="mt4win rounded win w-full relative block mx-auto max-w-[350px] text-sm text-center text-white font-bold  px-5 my-4 bg-black md:mx-0 hover:scale-[1.05] pt-[17px] pb-[20px] duration-300 ">{postLang["plt1-dl"]}</a>
                <p className="mx-auto max-w-[125px] w-full my-6 md:ml-[22%]"><img src={imgMt4} alt="" /></p>
                <p className="text-[12px]">{postLang["plt1-cap_1"]}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Feature pt-11 bg-fixed">
        <div className="max-w-xl mx-auto  md:max-w-5xl px-6 mb-10">
          <div className="text-left md:mt-5 mb-10">
            <h2 className="font-semibold tracking-wide text-[26px] md:text-[40px]">{postLang["plt1-h2_2"]}</h2>
          </div>
          <div className="mt-10 md:mb-12 mb-0  flex flex-wrap">
            <div className="max-w-[400px] w-full mb-5 md:pb-0  md:mr-10 relative"><img src={listSVG1} className="absolute" alt="" /><span className="ml-7">{postLang["plt1-txt_6"]}</span></div>
            <div className="max-w-[400px] w-full mb-5 md:pb-0  md:mr-10 relative"><img src={listSVG1} className="absolute" alt="" /><span className="ml-7">{postLang["plt1-txt_7"]}</span></div>
            <div className="max-w-[400px] w-full mb-5 md:pb-0 md:mr-10 relative"><img src={listSVG1} className="absolute" alt="" /><span className="ml-7">{postLang["plt1-txt_8"]}</span></div>
            <div className="max-w-[400px] w-full mb-5 md:mr-10 relative"><img src={listSVG1} className="absolute" alt="" /><span className="ml-7">{postLang["plt1-txt_9"]}</span></div>
            <div className="max-w-[400px] w-full mb-5 md:mr-10 relative"><img src={listSVG1} className="absolute" alt="" /><span className="ml-7">{postLang["plt1-txt_10"]}</span></div>
            <div className="max-w-[400px] w-full mb-5 md:mr-10 relative"><img src={listSVG1} className="absolute" alt="" /><span className="ml-7">{postLang["plt1-txt_11"]}</span></div>
            <div className="max-w-[400px] w-full mb-5 md:mr-10 relative"><img src={listSVG1} className="absolute" alt="" /><span className="ml-7">{postLang["plt1-txt_12"]}</span></div>
            <div className="max-w-[400px] w-full mb-5 md:mr-10 relative"><img src={listSVG1} className="absolute" alt="" /><span className="ml-7">{postLang["plt1-txt_13"]}</span></div>
          </div>
          <div className="mt-5 mb-12"> <a href={platDl1} className="mt4win rounded  win relative block md:max-w-xs text-sm text-center text-white font-bold px-5 my-4 bg-black hover:scale-[1.05] pt-[17px] pb-[20px]">{postLang["plt1-dl"]}</a>
            <p className="text-[12px] text-left">{postLang["plt1-cap_1"]}</p>
          </div>
        </div>
        <div className="mt-11 py-14 py-14  md:py-10 md:py-10">
          <div className="md:mx-auto md:max-w-5xl flex justify-center">
        <a href={signPath1} className="cont_regi01 rounded block md:max-w-xs mx-5  text-center text-white font-bold  px-16 my-2 bg-black hover:scale-[1.05] pt-[18px] pb-[20px] duration-300">{postLang["open"]}</a>
        </div></div>
      </div>
    </Layout>
  </>
  )
}
export default Mt4DesktopWin